import React, { useState, useEffect, memo, lazy, Suspense } from 'react'
import { Button } from './Button'

const PostListing = lazy(() => import('./PostListing'))

const PostFilterList = ({ posts, heading = '' }) => {
  const [searchTerm, handleSearch] = useState('')

  const handleChange = (event) => {
    const { value } = event?.target
    handleSearch(value)
  }

  const [filteredPosts, filterPosts] = useState(posts)

  const filterPost = () => {
    const filteredPost = posts.filter((post) =>
      post.node.frontmatter.title.toLowerCase().includes(searchTerm.toLowerCase()),
    )
    filterPosts(filteredPost)
  }

  useEffect(filterPost, [searchTerm])

  const filterCount = filteredPosts.length

  const postCount = posts.length

  return (
    <>
      {heading && <h1 className="articles-title">{`${postCount} ${heading} available`}</h1>}
      <div className="search-container">
        <div className="search-wrapper flex">
          <input
            className="search"
            type="text"
            name="searchTerm"
            aria-label="Post Search"
            value={searchTerm}
            placeholder="Type here to filter posts..."
            onChange={handleChange}
          />
          <div className="filter-count">{filterCount}</div>
        </div>
        {/* <div className="galleryLink flex">
          <ButtonLink simpleLink target="_self" href="/gallery">
            Go to Gallery
          </ButtonLink>
        </div> */}
      </div>
      {filterCount < 1 ? (
        <Button style={{ marginTop: '1rem' }} onClick={() => handleSearch('')}>
          {' '}
          Clear Search{' '}
        </Button>
      ) : (
        <Suspense fallback={<span>Loading...</span>}>
          <PostListing postEdges={filteredPosts} />
        </Suspense>
      )}
    </>
  )
}

export default memo(
  PostFilterList,
  (prevState, nextState) => prevState.searchTerm === nextState.searchTerm,
)
