import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../layout/index'
import PostFilterList from '../components/PostFilterList'
// import Accordion from '../components/Accordion'
// import contentType from '../configs/contentType.config'
import config from '../../data/SiteConfig'

const Content = ({ data, location }) => {
  const posts = data?.posts?.edges

  // const [expanded, setExpanded] = useState(1)
  const pageTitle = `Content – ${config.siteTitle} | Writings | Blogs | Articles`
  return (
    <Layout
      title={pageTitle}
      seoDescription={`Articles, Blogs, Writings, Content, Experiences by ${config.siteTitle}`}
      href={location.href}
    >
      <div className="container">
        <PostFilterList heading="Posts" posts={posts} />
        {/* {contentType.map(content => (
          <Accordion header={content.heading} panelId={content.id} expanded={expanded} setExpanded={setExpanded}>
            <PostFilterList heading='Posts' posts={posts} />
          </Accordion>
        ))} */}
      </div>
    </Layout>
  )
}

export default Content

export const pageQuery = graphql`
  query BlogQuery {
    posts: allMarkdownRemark(limit: 2000, sort: { fields: { date: DESC } }) {
      edges {
        node {
          fields {
            slug
            date
          }
          excerpt(pruneLength: 250)
          timeToRead
          frontmatter {
            title
            description
            tags
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 200, placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
              }
            }
            categories
            date
            template
          }
        }
      }
    }
  }
`
