import React, { memo } from 'react'
import config from '../../data/SiteConfig'

const SEO = ({
  postNode,
  postSEO,
  seoDescription,
  seoImage = '',
  seoTitle,
  isThemeDark,
  date,
  href = new URL(config.pathPrefix, config.siteUrl).href,
  children,
}) => {
  let title
  let description
  let image = ''

  if (postSEO) {
    const postMeta = postNode.frontmatter
    title = postMeta.title ?? config.siteTitle
    description = seoDescription
      ? seoDescription
      : postMeta.description
        ? postMeta.description
        : postNode.excerpt
    image = seoImage ?? config.siteLogo
  } else {
    title = seoTitle ?? config.siteTitle
    image = seoImage ?? config.siteLogo
    description = seoDescription ?? config.siteDescription
  }
  image = new URL(image, config.siteUrl).href
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: href,
      name: config.siteTitle,
      alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
    },
  ]

  if (postSEO) {
    schemaOrgJSONLD.push(
      {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: [
          {
            '@type': 'ListItem',
            position: 1,
            item: {
              '@id': href,
              name: title,
              image,
            },
          },
        ],
      },
      {
        '@context': 'http://schema.org',
        '@type': 'BlogPosting',
        url: href,
        name: title,
        alternateName: config.siteTitleAlt ? config.siteTitleAlt : '',
        headline: title,
        image: {
          '@type': 'ImageObject',
          url: image,
        },
        description,
        author: config.siteTitle,
        datePublished: date,
        publisher: config.siteUrl,
      },
    )
  }

  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      <meta name="viewport" content="width=device-width" />
      <meta name="keywords" content="Ashmeet Sehgal, blogs, portfolio, arcticles" />
      <meta name="author" content="Ashmeet Sehgal" />

      <script type="application/ld+json">{JSON.stringify(schemaOrgJSONLD)}</script>

      <meta property="fb:app_id" content={config.FB_APP_ID} />

      <meta name="theme-color" content={isThemeDark ? config.darkThemeColor : config.themeColor} />

      <meta property="og:url" content={href} />

      <meta property="og:title" content={title} />
      <meta property="og:type" content="blog" />

      <meta property="og:description" content={description} />

      <meta property="og:image" content={image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={config.userTwitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta property="twitter:url" content={href} />
      <meta name="twitter:image" content={image} />
      <meta name="twitter:site" content="@ashmeetsehgal" />
      {children}
    </>
  )
}

export default memo(SEO)
